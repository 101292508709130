import React, { useEffect, useState } from "react";
import { Popper } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { useMediaQuery, useTheme } from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import LunaSearchCategories from "./lunaSearchCategories";
import mixpanel from "mixpanel-browser";
import useStyles from "./styles";
import {
  getLunaSearchPrompts,
  getLunaSearchSuggestions,
} from "services/aiServices";
import LunaSearchSuggestedPrompts from "./lunaSearchSuggestedPrompts";
import LunaSearchSuggestedActions from "./lunaSearchSuggestedActions";
import LoadingStateHorizontal from "../LoadingStateHorizontal/LoadingStateHorizontal";
import LunaSearchLayers from "./lunaSearchLayers";

const LunaSearchPopper = (props) => {
  const classes = useStyles();
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down("xs"));
  const [categories, setCategories] = useState([]);
  const [initialCategories, setInitialCategories] = useState([]);
  const [layers, setLayers] = useState([]);
  const [suggestedPrompts, setSuggestedPrompts] = useState([]);
  const [initialSuggestedPrompts, setInitialSuggestedPrompts] = useState([]);
  const [suggestedActions, setSuggestedActions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedLayer, setSelectedLayer] = useState();
  const [selectedSuggestedPrompt, setSelectedSuggestedPrompt] = useState();
  const [swapSuggestedActions, setSwapSuggestedActions] = useState(false);
  const [loadingPromptActions, setLoadingPromptActions] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const { id, model, anchor, onHandleSelectedAction } = props;

  useEffect(() => {
    const getSuggestions = async () => {
      setLoadingCategories(true);
      const suggestions = await getLunaSearchSuggestions(model);
      const categoriesResponse = suggestions.data.categories.map((category) => {
        if (category.value === "All") {
          setSelectedCategory(category);
          return { ...category, selected: true };
        }
        return { ...category, selected: false };
      });
      setCategories(categoriesResponse);
      setInitialCategories(categoriesResponse);
      setSuggestedPrompts(suggestions.data.suggestedPrompts);
      setInitialSuggestedPrompts(suggestions.data.suggestedPrompts);
      if (model === "locations") {
        let layerId;
        const layersResponse = suggestions.data.layers.map((layer) => {
          if (layer.layer === "Locations") {
            layerId = layer.id;
            setSelectedLayer(layer);
            return { ...layer, selected: true };
          }
          return { ...layer, selected: false };
        });
        setLayers(layersResponse);
        const categoryIds = [];
        const filteredCategories = categoriesResponse.filter((category) => {
          return parseInt(category.model_id) === parseInt(layerId);
        });
        filteredCategories.forEach((category) => {
          categoryIds.push(parseInt(category.id));
        });
        setCategories(filteredCategories);
        const filteredSuggestedPrompts =
          suggestions.data.suggestedPrompts.filter((suggestedPrompt) =>
            categoryIds.includes(parseInt(suggestedPrompt.category_id))
          );
        setSuggestedPrompts(filteredSuggestedPrompts);
      }
      setLoadingCategories(false);
    };
    getSuggestions();
  }, [model]);

  useEffect(() => {
    if (categories.length > 0 && !Boolean(anchor)) {
      const newCategories = categories.map((category) => {
        if (category.value === "All") {
          setSelectedCategory(category);
          return { ...category, selected: true };
        }
        return { ...category, selected: false };
      });
      setCategories(newCategories);
      if (model === "locations") {
        const newLayers = layers.map((layer) => {
          if (layer.layer === "Locations") {
            setSelectedLayer(layer);
            return { ...layer, selected: true };
          }
          return { ...layer, selected: false };
        });
        setLayers(newLayers);
      }
      setSwapSuggestedActions(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anchor]);

  useEffect(() => {
    if (swapSuggestedActions) {
      const getSuggestedActions = async () => {
        setLoadingPromptActions(true);
        const actions = await getLunaSearchPrompts(selectedSuggestedPrompt?.id);
        setSuggestedActions(actions.data.suggestedActions);
        setLoadingPromptActions(false);
      };
      getSuggestedActions();
    }
  }, [swapSuggestedActions, selectedSuggestedPrompt]);

  useEffect(() => {
    const categoryIds = [];
    const filteredCategories = initialCategories.filter((category) => {
      return parseInt(category.model_id) === parseInt(selectedLayer?.id);
    });
    const mappedCategories = filteredCategories.map((category) => {
      if (category.value === "All") {
        return { ...category, selected: true };
      }
      return { ...category, selected: false };
    });
    mappedCategories.forEach((category) => {
      if (category.value === "All") {
        setSelectedCategory({ ...category, selected: true });
      }
      categoryIds.push(parseInt(category.id));
    });
    setCategories(mappedCategories);
    const filteredSuggestedPrompts = initialSuggestedPrompts.filter(
      (suggestedPrompt) =>
        categoryIds.includes(parseInt(suggestedPrompt.category_id))
    );
    setSuggestedPrompts(filteredSuggestedPrompts);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedLayer]);

  const onHandleSelectedCategory = (categoryId) => {
    let trackCategory;
    const newCategories = categories.map((category) => {
      if (parseInt(category.id) === parseInt(categoryId)) {
        setSelectedCategory(category);
        trackCategory = category;
        category.selected = true;
      } else {
        category.selected = false;
      }
      return category;
    });
    setCategories(newCategories);
    if (process.env.REACT_APP_ENV === "production")
      mixpanel.track(`Luna Search ${model}`, { category: trackCategory.value });
  };

  const onHandleSelectedLayer = (selectedLayer) => {
    let trackLayer;
    const newLayers = layers.map((layer) => {
      if (parseInt(layer.id) === parseInt(selectedLayer.id)) {
        setSelectedLayer(layer);
        trackLayer = layer;
        layer.selected = true;
      } else {
        layer.selected = false;
      }
      return layer;
    });
    setLayers(newLayers);
    if (process.env.REACT_APP_ENV === "production")
      mixpanel.track(`Luna Search ${model}`, { layer: trackLayer.layer });
  };

  const onHandleSelectedSuggestedPrompt = (suggestedPrompt) => {
    setSelectedSuggestedPrompt(suggestedPrompt);
    setSwapSuggestedActions(true);
    if (process.env.REACT_APP_ENV === "production")
      mixpanel.track(`Luna Search ${model}`, {
        suggestedPrompt: suggestedPrompt.value,
      });
  };

  const onHandleSelectedSuggestedAction = (suggestedAction) => {
    onHandleSelectedAction(suggestedAction);
    setSwapSuggestedActions(false);
    let result = suggestedAction.prompt;
    suggestedAction.placeholders.forEach((placeholder, index) => {
      const placeholderTag = `\${placeholder${index + 1}}`;
      result = result.replace(placeholderTag, `<${placeholder}>`);
    });
    if (process.env.REACT_APP_ENV === "production")
      mixpanel.track(`Luna Search ${model}`, {
        suggestedAction: result,
      });
  };

  return (
    <Popper
      disablePortal={true}
      id={id}
      open={Boolean(anchor)}
      anchorEl={anchor}
      style={{
        zIndex: 5,
        width: "100%",
      }}
      modifiers={{
        offset: {
          enabled: true,
          offset: model === "locations" ? "0, 0" : "-8, 0",
        },
      }}
    >
      <Box
        className={
          model === "locations"
            ? classes.popperSearchLocation
            : classes.popperSearch
        }
      >
        {swapSuggestedActions ? (
          <LunaSearchSuggestedActions
            selectedSuggestedPrompt={selectedSuggestedPrompt}
            suggestedActions={suggestedActions}
            loading={loadingPromptActions}
            onHandleSelectedAction={onHandleSelectedSuggestedAction}
          />
        ) : (
          <>
            {loadingCategories ? (
              <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flex: "1 1",
                  background: "white",
                  padding: 20,
                  margin: 20,
                }}
              >
                <LoadingStateHorizontal isVisible />
              </Box>
            ) : (
              <>
                {model === "locations" && (
                  <LunaSearchLayers
                    layers={layers}
                    onSelectedLayer={onHandleSelectedLayer}
                    model={model}
                  />
                )}
                <LunaSearchCategories
                  categories={categories}
                  setCategories={setCategories}
                  onSelectCategory={onHandleSelectedCategory}
                  model={model}
                />
                {model === "locations" && isXs ? (
                  <Box style={{ padding: "0px 18px 18px 18px" }}>
                    <Divider
                      style={{
                        width: "100%",
                        background: "#ECECEC",
                      }}
                    />
                  </Box>
                ) : null}
                <LunaSearchSuggestedPrompts
                  suggestedPrompts={suggestedPrompts}
                  selectedCategory={selectedCategory}
                  selectedSuggestedPrompt={selectedSuggestedPrompt}
                  onHandleClick={onHandleSelectedSuggestedPrompt}
                  selectedLayer={selectedLayer}
                />
              </>
            )}
          </>
        )}
      </Box>
    </Popper>
  );
};

export default LunaSearchPopper;
