import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  useFetchLocationDetails,
  useFetchLocationImages,
  useFetchLocationComments,
  useFetchLocationSurveys,
  useFetchLocationMetrics,
  useFetchLocationInsights,
  useFetchLocationCompletedWorktickets,
  useFetchLocationRecentInspections,
  useFetchLocationReactiveRequests,
  useFetchLocationDetailFilters,
} from "pages/locationDetails/api";

const defaultState = {
  completedWorkticketFilters: {},
  completedWorkticketDates: {},
  completedWorkticketPage: 1,
  recentInspectionsFilters: {},
  recentInspectionsDates: {},
  recentInspectionsPage: 1,
  reactiveRequestsFilters: {},
  reactiveRequestsDates: {},
  reactiveRequestsPage: 1,
  locationInsightsFilters: {},
  locationInsightsPage: 1,
  surveysPage: 1,
  commentsPages: {
    internal: 1,
    partner: 1,
    customer: 1,
  },
  keyMetricsFilters: {},
  drawerOpen: false,
  selectedFiltersCount: {},
};

export const LocationDetailsContext = createContext();
export const LocationDetailsDispatchContext = createContext();

function formatDate(date) {
  if (!(date instanceof Date)) return date;
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
}

function buildQueryString(filters, page = 1, limit = 10) {
  console.log("=== buildQueryString ===");
  console.log("=== filters ===", filters);
  let queryString = `?limit=${limit}&page=${page}`;

  if (Object.keys(filters).length === 0) return queryString;

  const filteredFilters = Object.fromEntries(
    Object.entries(filters).filter(
      ([key, value]) => value != null && typeof value !== "undefined"
    )
  );

  const filterQueryString = Object.entries(filteredFilters)
    .map(([key, value]) => {
      if (Array.isArray(value)) {
        return value.map((val) => `${key}=${val}`).join("&");
      }
      return `${key}=${value}`;
    })
    .join("&");

  return queryString + (filterQueryString ? `&${filterQueryString}` : "");
}

function locationDetailsReducer(state, action) {
  switch (action.type) {
    case "SET_COMPLETED_WORKTICKETS_DATES":
      return {
        ...state,
        completedWorkticketDates: {
          start_date: formatDate(action.startDate),
          end_date: formatDate(action.endDate),
        },
      };
    case "SET_RECENT_INSPECTIONS_DATES":
      return {
        ...state,
        recentInspectionsDates: {
          start_date: formatDate(action.startDate),
          end_date: formatDate(action.endDate),
        },
      };
    case "SET_REACTIVE_REQUESTS_DATES":
      return {
        ...state,
        reactiveRequestsDates: {
          start_date: formatDate(action.startDate),
          end_date: formatDate(action.endDate),
        },
      };
    case "SET_KEY_METRICS_DATES":
      return {
        ...state,
        keyMetricsDates: {
          start_date: formatDate(action.startDate),
          end_date: formatDate(action.endDate),
        },
      };
    case "SET_COMPLETED_WORKTICKETS_FILTERS":
      return {
        ...state,
        completedWorkticketFilters: {
          ...action.filters,
        },
        selectedFiltersCount: {
          ...state.selectedFiltersCount,
          COMPLETED_WORKTICKETS: action.count,
        },
      };
    case "SET_RECENT_INSPECTIONS_FILTERS":
      return {
        ...state,
        recentInspectionsFilters: {
          ...action.filters,
        },
        selectedFiltersCount: {
          ...state.selectedFiltersCount,
          RECENT_INSPECTIONS: action.count,
        },
      };
    case "SET_REACTIVE_REQUESTS_FILTERS":
      return {
        ...state,
        reactiveRequestsFilters: {
          ...action.filters,
        },
        selectedFiltersCount: {
          ...state.selectedFiltersCount,
          REACTIVE_REQUESTS: action.count,
        },
      };
    case "SET_KEY_METRICS_FILTERS":
      return {
        ...state,
        keyMetricsFilters: {
          ...action.filters,
        },
        selectedFiltersCount: {
          ...state.selectedFiltersCount,
          KEY_METRICS: action.count,
        },
      };
    case "OPEN_FILTER_DRAWER":
      return {
        ...state,
        drawerOpen: true,
        activeDrawerSection: action.activeDrawerSection,
      };
    case "CLOSE_FILTER_DRAWER":
      return {
        ...state,
        drawerOpen: false,
        activeDrawerSection: null,
      };
    case "SET_COMPLETED_WORKTICKETS_PAGE":
      return {
        ...state,
        completedWorkticketPage: action.page,
      };
    case "SET_REACTIVE_REQUESTS_PAGE":
      return {
        ...state,
        reactiveRequestsPage: action.page,
      };
    case "SET_RECENT_INSPECTIONS_PAGE":
      return {
        ...state,
        recentInspectionsPage: action.page,
      };
    case "SET_LOCATION_INSIGHTS_PAGE":
      return {
        ...state,
        locationInsightsPage: action.page,
      };
    case "SET_SURVEYS_PAGE":
      return {
        ...state,
        surveysPage: action.page,
      };
    case "SET_COMMENTS_PAGE":
      return {
        ...state,
        commentsPages: {
          ...state.commentsPages,
          [action.tab]: action.page,
        },
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export const LocationDetailsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(locationDetailsReducer, defaultState);
  const [workticketPage, setWorkticketPage] = useState(1);
  const [activeCommentsTab, setActiveCommentsTab] = useState("internal");

  const filters = useFetchLocationDetailFilters();
  const details = useFetchLocationDetails();
  const images = useFetchLocationImages();
  const commentsQueryString = useMemo(() => {
    return {
      internal: buildQueryString({}, state.commentsPages.internal, 6),
      partner: buildQueryString({}, state.commentsPages.partner, 6),
      customer: buildQueryString({}, state.commentsPages.customer, 6),
    };
  }, [state.commentsPages]);
  const comments = useFetchLocationComments(
    commentsQueryString,
    activeCommentsTab
  );
  const surveysQueryString = useMemo(() => {
    return buildQueryString({}, state.surveysPage, 6);
  }, [state.surveysPage]);
  const surveys = useFetchLocationSurveys(surveysQueryString);

  const keyMetricsQueryString = useMemo(() => {
    return buildQueryString({
      ...state.keyMetricsFilters,
      ...state.keyMetricsDates,
    });
  }, [state.keyMetricsFilters, state.keyMetricsDates]);

  const locationInsightsQueryString = useMemo(() => {
    return buildQueryString(
      {
        ...state.locationInsightsFilters,
      },
      state.locationInsightsPage,
      6
    );
  }, [state.locationInsightsFilters, state.locationInsightsPage]);

  const completedWorkticketsQueryString = useMemo(() => {
    return buildQueryString(
      {
        ...state.completedWorkticketFilters,
        ...state.completedWorkticketDates,
      },
      state.completedWorkticketPage,
      8
    );
  }, [
    state.completedWorkticketFilters,
    state.completedWorkticketDates,
    state.completedWorkticketPage,
  ]);

  const recentInspectionsQueryString = useMemo(() => {
    return buildQueryString(
      {
        ...state.recentInspectionsFilters,
        ...state.recentInspectionsDates,
      },
      state.recentInspectionsPage,
      6
    );
  }, [
    state.recentInspectionsFilters,
    state.recentInspectionsDates,
    state.recentInspectionsPage,
  ]);

  const reactiveRequestsQueryString = useMemo(() => {
    return buildQueryString(
      {
        ...state.reactiveRequestsFilters,
        ...state.reactiveRequestsDates,
      },
      state.reactiveRequestsPage,
      8
    );
  }, [
    state.reactiveRequestsFilters,
    state.reactiveRequestsDates,
    state.reactiveRequestsPage,
  ]);

  const metrics = useFetchLocationMetrics(keyMetricsQueryString);
  const insights = useFetchLocationInsights(locationInsightsQueryString);
  const completedOnTimeWorktickets = useFetchLocationCompletedWorktickets(
    completedWorkticketsQueryString,
    "ontime",
    workticketPage
  );
  const completedOnSiteWorktickets = useFetchLocationCompletedWorktickets(
    completedWorkticketsQueryString,
    "onsite",
    workticketPage
  );
  const fullyCompliantWorktickets = useFetchLocationCompletedWorktickets(
    completedWorkticketsQueryString,
    "fully_compliant",
    workticketPage
  );
  const recentInspections = useFetchLocationRecentInspections(
    recentInspectionsQueryString
  );
  const reactiveRequests = useFetchLocationReactiveRequests(
    reactiveRequestsQueryString
  );

  const queries = [
    details,
    images,
    comments,
    surveys,
    metrics,
    filters,
    insights,
    completedOnTimeWorktickets,
    completedOnSiteWorktickets,
    fullyCompliantWorktickets,
    recentInspections,
    reactiveRequests,
  ];

  const isLoading = queries.filter((query) => query.isLoading).length > 3;
  const errors = queries.map((query) => query.error).filter(Boolean);

  const value = {
    ...state,
    isLoading,
    hasErrors: errors.length > 0,
    errors,
    setWorkticketPage,
    filters,
    details,
    images: images?.data || [],
    comments,
    surveys,
    metrics,
    insights,
    completedOnTimeWorktickets: completedOnTimeWorktickets,
    completedOnSiteWorktickets: completedOnSiteWorktickets,
    fullyCompliantWorktickets: fullyCompliantWorktickets,
    recentInspections: recentInspections,
    reactiveRequests: reactiveRequests,
    selectedFiltersCount: state.selectedFiltersCount,
    setActiveCommentsTab,
  };

  return (
    <LocationDetailsContext.Provider value={value}>
      <LocationDetailsDispatchContext.Provider value={dispatch}>
        {children}
      </LocationDetailsDispatchContext.Provider>
    </LocationDetailsContext.Provider>
  );
};

export const useLocationDetailsDispatch = () => {
  const context = useContext(LocationDetailsDispatchContext);
  if (context === undefined) {
    throw new Error(
      "useLocationDetailsDispatch must be used within a LocationDetailsProvider"
    );
  }
  return context;
};
