import mapboxgl from "mapbox-gl";
import walgreensImage from "assets/walgreens-test-1.png";
import walgreensImage2 from "assets/walgreens-test-2.png";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import LocationCard from "./locationCard";
import WorkticketCard from "./workticketCard";
import PartnerCard from "./partnerCard";
class MapLayerManager {
  constructor(map, options = {}) {
    this.map = map;
    this.options = options;
    this.layersAdded = false;
  }

  static LayerTypes = {
    LOCATIONS: {
      sourceId: "locations",
      layerIds: {
        normal: "locations-normal",
        hover: "locations-hover",
      },
      icons: {
        normal: "loc-normal",
        hover: "loc-hover",
      },
      size: 0.35,
    },
    WORKTICKETS: {
      sourceId: "worktickets",
      layerIds: {
        normal: "worktickets-active",
        hover: "worktickets-hover",
      },
      icons: {
        normal: "wt-normal",
        hover: "wt-hover",
      },
      minZoom: 4,
      size: 0.5,
    },
    PARTNERS: {
      sourceId: "partners",
      layerIds: {
        normal: "partners-normal",
        hover: "partners-hover",
      },
      icons: {
        normal: "par-normal",
        hover: "par-hover",
      },
      size: 0.8,
    },
    BUILDINGS: {
      sourceId: "composite",
      layerIds: {
        normal: "add-3d-buildings",
      },
      sourceLayer: "building",
      minZoom: 15,
      type: "fill-extrusion",
      paint: {
        "fill-extrusion-color": "#aaa",
        "fill-extrusion-height": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          0,
          15.05,
          ["get", "height"],
        ],
        "fill-extrusion-base": [
          "interpolate",
          ["linear"],
          ["zoom"],
          15,
          0,
          15.05,
          ["get", "min_height"],
        ],
        "fill-extrusion-opacity": 0.6,
      },
      filter: ["==", "extrude", "true"],
    },
  };

  static MAX_MAP_PITCH_IN_DEGREES = 60;

  setVisibility(visible) {
    if (this.layersAdded) {
      Object.values(this.currentConfig.layerIds).forEach((layerId) => {
        if (this.map.getLayer(layerId)) {
          this.map.setLayoutProperty(
            layerId,
            "visibility",
            visible ? "visible" : "none"
          );
        }
      });
    }
  }

  addLayersForType(layerType, geojsonData) {
    console.log("=== addLayersForType ===");
    console.log("=== layerType ===", layerType);
    console.log("=== layersAdded ===", this.layersAdded);
    if (this.layersAdded) return;

    const config = MapLayerManager.LayerTypes[layerType];
    this.currentConfig = config;

    this.cleanupExistingLayers(config);
    this.addMapSource(config.sourceId, geojsonData);
    this.addLayers(config);
    this.setupEventHandlers(config);

    this.layersAdded = true;
  }

  cleanupExistingLayers(config) {
    Object.values(config.layerIds).forEach((layerId) => {
      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }
    });

    if (this.map.getSource(config.sourceId)) {
      this.map.removeSource(config.sourceId);
    }
  }

  addMapSource(sourceId, geojsonData) {
    this.map.addSource(sourceId, {
      type: "geojson",
      data: geojsonData,
    });
  }

  addLayers(config) {
    const baseLayerConfig = {
      type: "symbol",
      source: config.sourceId,
      layout: {
        "icon-size": config.size,
        "icon-allow-overlap": true,
        "icon-padding": 0,
      },
    };

    if (config.minZoom) {
      baseLayerConfig.minzoom = config.minZoom;
    }

    // Add normal layer
    this.map.addLayer({
      ...baseLayerConfig,
      id: config.layerIds.normal,
      layout: {
        ...baseLayerConfig.layout,
        "icon-image": config.icons.normal,
      },
    });

    // Add hover layer
    this.map.addLayer({
      ...baseLayerConfig,
      id: config.layerIds.hover,
      layout: {
        ...baseLayerConfig.layout,
        "icon-image": config.icons.hover,
      },
      filter: ["==", "id", ""],
    });
  }

  updateData(newData) {
    if (!this.map || !this.currentConfig || !this.layersAdded) return;

    const sourceId = this.currentConfig.sourceId;
    const source = this.map.getSource(sourceId);

    if (!source) {
      console.warn(`Source ${sourceId} not found`);
      return;
    }

    try {
      // Update the source data
      source.setData(newData);
    } catch (error) {
      console.error(`Error updating data for source ${sourceId}:`, error);
      throw error;
    }
  }

  setupEventHandlers(config) {
    // Remove existing handlers
    this.map.off("click", config.layerIds.hover);
    this.map.off("mousemove", config.layerIds.normal);
    this.map.off("mouseleave", config.layerIds.normal);

    // Add hover handlers
    this.map.on("mousemove", config.layerIds.normal, (e) => {
      this.handleHover(e, config);
    });

    this.map.on("mouseleave", config.layerIds.normal, () => {
      this.handleHoverReset(config);
    });

    // Add click handler
    this.map.on("click", config.layerIds.hover, (e) => {
      this.handleClick(e, config);
    });
  }

  propogateWheelEventsToMap(popup, map) {
    popup.getElement().addEventListener("wheel", (event) => {
      map.scrollZoom.wheel(event);
    });
  }

  handleHover(e, config) {
    const features = this.map.queryRenderedFeatures(e.point, {
      layers: [config.layerIds.normal],
    });

    if (features.length > 0) {
      this.map.getCanvas().style.cursor = "pointer";
      const hoveredId = features[0].properties.id;

      if (hoveredId !== this.hoveredFeatureId) {
        if (this.hoveredFeatureId) {
          this.handleHoverReset(config);
        }

        this.hoveredFeatureId = hoveredId;
        this.map.setFilter(config.layerIds.hover, ["==", "id", hoveredId]);

        if (!this.options.hoverPopupRef.current) {
          this.handleHoverPopup(e, features[0]);
        }
      }
    } else {
      this.handleHoverReset(config);
    }
  }

  handleHoverReset(config) {
    this.map.getCanvas().style.cursor = "";
    this.hoveredFeatureId = null;
    this.map.setFilter(config.layerIds.hover, ["==", "id", ""]);
    this.clearHoverPopup();
  }

  handleClick(e, config) {
    if (this.options.isClickProcessingRef?.current) return;
    if (this.options.isClickProcessingRef) {
      this.options.isClickProcessingRef.current = true;
    }

    this.clearHoverPopup();

    if (e.features.length > 0) {
      this.createPopup(e.features[0], config);
    }

    if (this.options.isClickProcessingRef) {
      this.options.isClickProcessingRef.current = false;
    }
  }

  clearHoverPopup() {
    if (this.options.hoverPopupRef?.current) {
      this.options.hoverPopupRef.current.remove();
      this.options.hoverPopupRef.current = null;
    }
  }

  handleHoverPopup(e, feature) {
    // Implementation depends on popup requirements
    // This can be overridden in child classes
  }

  createMapPopup({
    coordinates,
    content,
    map,
    currentPopupRef,
    hoverPopupRef,
    isFlyingRef,
    isClickProcessingRef,
    theme,
    popupConfig = {
      closeButton: false,
      closeOnClick: true,
      offset: 25,
    },
  }) {
    // Clean up existing popups
    if (currentPopupRef?.current) {
      currentPopupRef.current.remove();
      currentPopupRef.current = null;
    }
    if (hoverPopupRef?.current) {
      hoverPopupRef.current.remove();
      hoverPopupRef.current = null;
    }

    const handleFlyTo = () => {
      isFlyingRef.current = true;
      console.log("Flying to:", coordinates);
      map.flyTo({
        center: coordinates,
        zoom: 18,
        offset: [0, -250],
        essential: true,
        pitch: MapLayerManager.MAX_MAP_PITCH_IN_DEGREES,
      });

      map.once("moveend", () => {
        isFlyingRef.current = false;
        isClickProcessingRef.current = false;
      });
    };

    const popupNode = document.createElement("div");
    ReactDOM.render(
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {content(handleFlyTo)}
      </ThemeProvider>,
      popupNode
    );

    const newPopup = new mapboxgl.Popup(popupConfig)
      .setLngLat(coordinates)
      .setDOMContent(popupNode)
      .addTo(map);

    // Set the current popup reference
    currentPopupRef.current = newPopup;

    return {
      popup: newPopup,
      node: popupNode,
    };
  }
}

export class LocationLayerManager extends MapLayerManager {
  handleHoverPopup(e, feature) {
    const coordinates = feature.geometry.coordinates.slice();
    const locationName = feature.properties.name || "Location Name Here";

    this.options.hoverPopupRef.current = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      anchor: "left",
      offset: 20,
      className: "location-hover-popup",
    })
      .setLngLat(coordinates)
      .setHTML(
        `
          <div class="location-hover-label">
            <div class="location-icon"></div>
            <div class="location-name">${locationName}</div>
          </div>
        `
      )
      .addTo(this.map);

    this.propogateWheelEventsToMap(
      this.options.hoverPopupRef.current,
      this.map
    );
  }

  createPopup(feature) {
    const testLocation = {
      customer: "Walgreens",
      location: "Location Name Here",
      lastCanvassed: "05/08/2024",
      address:
        "7020 - Scripps Research Institute - Jupiter Campus. Tampa, Florida.",
      images: [walgreensImage, walgreensImage2],
      trades: [
        "Pressure Washing",
        "Janitorial",
        "Carpet Care",
        "Window Washing",
        "Exterior Maintenance",
      ],
    };

    const coordinates = [
      parseFloat(feature.properties.longitude),
      parseFloat(feature.properties.latitude),
    ];

    const locationData = { ...testLocation, coordinates };

    const { popup } = this.createMapPopup({
      coordinates: locationData.coordinates,
      content: (handleFlyTo) => (
        <LocationCard
          id={feature.properties.id}
          onCanvass={() => {
            if (this.options.history) {
              this.options.history.push(
                `/location-details/${feature.properties.id}`
              );
            }
          }}
          onFlyTo={handleFlyTo}
          onClose={() => {
            if (this.options.currentPopupRef?.current) {
              this.options.currentPopupRef.current.remove();
              this.options.currentPopupRef.current = null;
            }
          }}
        />
      ),
      map: this.map,
      ...this.options,
    });

    this.options.currentPopupRef.current = popup;
    this.propogateWheelEventsToMap(popup, this.map);
  }
}

export class WorkticketLayerManager extends MapLayerManager {
  static WorkticketTypes = {
    SERVICE: 1, // matches wt-1-*-* icons
    INSPECTION: 2, // matches wt-2-*-* icons
  };

  static WorkticketStatuses = {
    DEFAULT: 0,
    NOTSTARTED: 1,
    INPROGRESS: 2,
    OVERDUE: 3,
    NONCOMPLIANT: 4,
    ONTIME: 5,
    ONTIMEONSITE: 6,
    FULLYCOMPLIANT: 7,
  };

  addLayers(config) {
    const baseLayerConfig = {
      type: "symbol",
      source: config.sourceId,
      layout: {
        "icon-size": config.size,
        "icon-allow-overlap": true,
        "icon-padding": 0,
      },
    };

    if (config.minZoom) {
      baseLayerConfig.minzoom = config.minZoom;
    }

    // Add normal layer with icon expression
    this.map.addLayer({
      ...baseLayerConfig,
      id: config.layerIds.normal,
      layout: {
        ...baseLayerConfig.layout,
        "icon-image": [
          "let",
          "iconPrefix",
          [
            "case",
            [
              "==",
              ["get", "workticket_type"],
              WorkticketLayerManager.WorkticketTypes.SERVICE,
            ],
            "wt-service",
            "wt-inspection",
          ],
          [
            "concat",
            ["var", "iconPrefix"],
            "-",
            [
              "case",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.DEFAULT,
              ],
              "default",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.NOTSTARTED,
              ],
              "notstarted",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.INPROGRESS,
              ],
              "inprogress",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.OVERDUE,
              ],
              "overdue",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.NONCOMPLIANT,
              ],
              "noncompliant",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.ONTIME,
              ],
              "ontime",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.ONTIMEONSITE,
              ],
              "ontimeonsite",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.FULLYCOMPLIANT,
              ],
              "fullycompliant",
              "default",
            ],
          ],
        ],
      },
    });

    // Add hover layer
    this.map.addLayer({
      ...baseLayerConfig,
      id: config.layerIds.hover,
      layout: {
        ...baseLayerConfig.layout,
        "icon-image": [
          "let",
          "iconPrefix",
          [
            "case",
            [
              "==",
              ["get", "workticket_type"],
              WorkticketLayerManager.WorkticketTypes.SERVICE,
            ],
            "wt-service",
            "wt-inspection",
          ],
          [
            "concat",
            ["var", "iconPrefix"],
            "-",
            [
              "case",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.DEFAULT,
              ],
              "default-hover",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.NOTSTARTED,
              ],
              "notstarted-hover",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.INPROGRESS,
              ],
              "inprogress-hover",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.OVERDUE,
              ],
              "overdue-hover",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.NONCOMPLIANT,
              ],
              "noncompliant-hover",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.ONTIME,
              ],
              "ontime-hover",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.ONTIMEONSITE,
              ],
              "ontimeonsite-hover",
              [
                "==",
                ["get", "status"],
                WorkticketLayerManager.WorkticketStatuses.FULLYCOMPLIANT,
              ],
              "fullycompliant-hover",
              "default-hover",
            ],
          ],
        ],
      },
      filter: ["==", "id", ""],
    });
  }

  // Add workticket-specific test data
  static testWorkticket = {
    id: 427598,
    number: "WT605700308a",
    subject: "Quarterly - Scrub & Refinish - BC Pines Center",
    due_date: "2022-04-30T04:00:00.000000Z",
    status: 0,
    workticket_category: 0,
    service_category: "General",
    preview: 0,
    compliance_ontime: 0,
    compliance_onsite: 0,
    compliance_document: 1,
    workticket_type: 1,
    location: {
      id: 1018,
      name: "Broward College - Pines Center",
      address: "16957 Sheridan Street",
      city: "South Ranches",
      state: "FL",
      zip: "33331",
      latitude: "26.0314882000",
      longitude: "-80.3747190000",
      timezone: "America/New_York",
      manager: {
        id: 1623,
        first_name: "Marjorie",
        last_name: "Hocutt",
        email: "marjorie.hocutt@encompassonsite.com",
        phone: "9548687020",
      },
      director: {
        id: 148158,
        first_name: "Demetrius",
        last_name: "Robinson",
        email: "demetrius.robinson@encompassonsite.com",
        phone: "2398497720",
      },
    },
    users: [
      {
        id: 4264,
        employee_number: "4281",
        first_name: "Jaime",
        last_name: "Munoz G",
        email: "jaimemunoz2@hotmail.com",
        phone: "9546040521",
        photo: null,
      },
      {
        id: 2681,
        employee_number: "3220",
        first_name: "Luz",
        last_name: "Sanchez Alvarez",
        email: "luz.sanchez@encompassonsite.com",
        phone: "5617277005",
        photo:
          "https://insights-secure-storage.nyc3.digitaloceanspaces.com/production/users/2681/files/profile-image/LuzSanchez2021-06-10%2012%3A20%3A08.jpg",
      },
    ],
  };

  // handleHoverPopup(e, feature) {
  //   const coordinates = feature.geometry.coordinates.slice();
  //   const workticketNumber = feature.properties.number || "Workticket Number";

  //   this.options.hoverPopupRef.current = new mapboxgl.Popup({
  //     closeButton: false,
  //     closeOnClick: false,
  //     anchor: "left",
  //     offset: 20,
  //     className: "workticket-hover-popup"
  //   })
  //     .setLngLat(coordinates)
  //     .setHTML(`
  //       <div class="workticket-hover-label">
  //         <div class="workticket-icon"></div>
  //         <div class="workticket-number">${workticketNumber}</div>
  //       </div>
  //     `)
  //     .addTo(this.map);

  //   this.propogateWheelEventsToMap(this.options.hoverPopupRef.current, this.map);
  // }

  createPopup(feature) {
    const coordinates = [
      parseFloat(feature.properties.longitude),
      parseFloat(feature.properties.latitude),
    ];

    const workticketData = {
      ...feature.properties,
      coordinates,
    };

    const { popup } = this.createMapPopup({
      coordinates: workticketData.coordinates,
      content: (handleFlyTo) => (
        <WorkticketCard
          id={workticketData.id}
          onFlyTo={handleFlyTo}
          onView={() => {
            if (this.options.history) {
              this.options.history.push(`/workticket/${workticketData.id}`);
            }
          }}
          onClose={() => {
            if (this.options.currentPopupRef?.current) {
              this.options.currentPopupRef.current.remove();
              this.options.currentPopupRef.current = null;
            }
          }}
        />
      ),
      map: this.map,
      ...this.options,
    });

    this.options.currentPopupRef.current = popup;
    this.propogateWheelEventsToMap(popup, this.map);
  }

  static randomOffset(radius) {
    const angle = Math.random() * 2 * Math.PI;
    const r = radius * Math.sqrt(Math.random());
    return [r * Math.cos(angle), r * Math.sin(angle)];
  }
}

export class PartnerLayerManager extends MapLayerManager {
  // Add workticket-specific test data
  static testPartner = {
    partner_id: 1,
    longitude: "-100.0000000000",
    latitude: "44.5000000000",
    name: "Alexa Williams",
    title: "Encompass Technician",
    current_address:
      "7020 - Scripps Research Institute - Jupiter Campus. Tampa, FL 33458",
    email: "alexa@cleaningabc.com",
    phone: "555-789-1234",
    company_name: "Cleaning Company ABC",
    trades: [
      "Janitorial",
      "Carpet Care",
      "Window Washing",
      "Exterior Maintenance",
    ],
  };

  // handleHoverPopup(e, feature) {
  //   const coordinates = feature.geometry.coordinates.slice();
  //   const workticketNumber = feature.properties.number || "Workticket Number";

  //   this.options.hoverPopupRef.current = new mapboxgl.Popup({
  //     closeButton: false,
  //     closeOnClick: false,
  //     anchor: "left",
  //     offset: 20,
  //     className: "workticket-hover-popup"
  //   })
  //     .setLngLat(coordinates)
  //     .setHTML(`
  //       <div class="workticket-hover-label">
  //         <div class="workticket-icon"></div>
  //         <div class="workticket-number">${workticketNumber}</div>
  //       </div>
  //     `)
  //     .addTo(this.map);

  //   this.propogateWheelEventsToMap(this.options.hoverPopupRef.current, this.map);
  // }

  createPopup(feature) {
    const coordinates = [
      parseFloat(feature.properties.longitude),
      parseFloat(feature.properties.latitude),
    ];

    const partnerData = {
      ...feature.properties,
      coordinates,
    };

    const { popup } = this.createMapPopup({
      coordinates: partnerData.coordinates,
      content: (handleFlyTo) => (
        <PartnerCard
          partner={partnerData}
          onFlyTo={handleFlyTo}
          onClose={() => {
            if (this.options.currentPopupRef?.current) {
              this.options.currentPopupRef.current.remove();
              this.options.currentPopupRef.current = null;
            }
          }}
        />
      ),
      map: this.map,
      ...this.options,
    });

    this.options.currentPopupRef.current = popup;
    this.propogateWheelEventsToMap(popup, this.map);
  }
}

export class BuildingsLayerManager extends MapLayerManager {
  addLayersForType(layerType, geojsonData) {
    if (this.layersAdded) return;

    const config = MapLayerManager.LayerTypes[layerType];
    this.currentConfig = config;

    this.cleanupExistingLayers(config);

    // Find the label layer to insert buildings beneath
    const layers = this.map.getStyle().layers;
    const labelLayerId = layers.find(
      (layer) => layer.type === "symbol" && layer.layout["text-field"]
    ).id;

    // Add the buildings layer
    this.map.addLayer(
      {
        id: config.layerIds.normal,
        source: config.sourceId,
        "source-layer": config.sourceLayer,
        type: config.type,
        minzoom: config.minZoom,
        filter: config.filter,
        paint: config.paint,
      },
      labelLayerId
    );

    this.layersAdded = true;
  }

  cleanupExistingLayers(config) {
    // Only remove the layer, not the source (since composite is a built-in source)
    Object.values(config.layerIds).forEach((layerId) => {
      if (this.map.getLayer(layerId)) {
        this.map.removeLayer(layerId);
      }
    });
  }
}
