import React from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { Button } from "@material-ui/core";
import useStyles from "./styles";
import clsx from "clsx";
import Divider from "@material-ui/core/Divider";

const LunaSearchLayers = (props) => {
  const classes = useStyles();
  const { layers, onSelectedLayer, model } = props;

  return (
    <Box
      className={clsx(classes.categoriesContainer, {
        [classes.categoriesContainerZero]: layers.length <= 1,
      })}
    >
      {model === "locations" && layers.length > 1 && (
        <>
          <Typography className={classes.title}>Layers</Typography>
          <Box className={classes.categoriesButtonContainer}>
            {layers.map((layer, index) => (
              <Button
                key={index}
                className={
                  layer.selected
                    ? classes.categoryButtonSelected
                    : classes.categoryButton
                }
                onClick={() => onSelectedLayer(layer)}
              >
                {layer.layer}
              </Button>
            ))}
          </Box>
          <Divider style={{ width: "100%", background: "#ECECEC" }} />
        </>
      )}
    </Box>
  );
};

export default LunaSearchLayers;
