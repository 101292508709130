import React, { useEffect, useRef, useState } from "react";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import lunaSolidPlaceholder from "../../../assets/icons/solidLunaSearch.jpg";
import SearchIcon from "@material-ui/icons/Search";
import clsx from "clsx";
import useStyles from "./styles";
import { OutlinedInput } from "@material-ui/core/index";

const placeholderWidths = {
  "Insert Name": "113px",
  "Insert Number": "128px",
  "Insert Address": "130px",
  "Insert Date": "105px",
  "Insert Percentage": "151px",
  "Insert Number/s": "140px",
  "Insert Email": "110px",
  "Insert Service/Trade": "168px",
  "Insert Service": "122px",
  "Insert Trade": "110px",
};

const replacePlaceholders = (prompt, placeholders, values) => {
  let offset = 0;
  const indices = {};
  const finalPrompt = prompt.replace(
    /\${placeholder(\d+)}/g,
    (match, number, index) => {
      const placeholderIndex = parseInt(number, 10) - 1;
      const replacement = values[placeholderIndex] || match;
      indices[number] = index + offset;
      offset += replacement.length - match.length;
      return replacement;
    }
  );
  return { finalPrompt, indices };
};

const LunaSearchInputParameters = (props) => {
  const classes = useStyles();
  const [isOverflow, setIsOverflow] = useState(false);
  const [inputValues, setInputValues] = useState({});
  const [lastInputChanged, setLastInputChanged] = useState();
  const { selectedAction, onHandlePromptFormat, model } = props;
  const containerRef = useRef(null);
  const inputRefs = useRef([]);

  const handleInputChange = (index, event, indexPlaceholder) => {
    setLastInputChanged(indexPlaceholder + 1);
    const newValues = { ...inputValues, [index]: event.target.value };
    setInputValues(newValues);
  };

  useEffect(() => {
    if (Object.keys(inputValues).length > 0) {
      const allFilled = Object.values(inputValues).every(
        (value) => value.trim() !== ""
      );
      if (allFilled) {
        const { finalPrompt, indices } = replacePlaceholders(
          selectedAction?.prompt,
          selectedAction?.placeholders,
          Object.values(inputValues)
        );
        onHandlePromptFormat(finalPrompt, indices[lastInputChanged]);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues, selectedAction]);

  useEffect(() => {
    if (selectedAction && selectedAction.placeholders) {
      const initialValues = {};
      selectedAction.placeholders.forEach((placeholder, index) => {
        initialValues[placeholder] = "";
      });
      setInputValues(initialValues);
    }
  }, [selectedAction]);

  useEffect(() => {
    if (inputRefs.current.length > 0) {
      inputRefs.current[inputRefs.current.length - 1].focus();
    }
  }, [inputRefs.current.length]);

  const calculateTextWidth = (text) => {
    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    context.font = "16px Arial"; // Use the same font as the input
    const metrics = context.measureText(text);
    return metrics.width;
  };

  const renderPromptWithPlaceholders = (prompt, placeholders) => {
    const parts = prompt.split(/(\${placeholder\d+})/g);
    return parts.map((part, index) => {
      const match = part.match(/\${placeholder(\d+)}/);
      if (match) {
        const placeholderIndex = parseInt(match[1], 10) - 1;
        const placeholderText = placeholders[placeholderIndex];
        const width = inputValues[placeholderText]
          ? calculateTextWidth(inputValues[placeholderText]) + 12
          : placeholderWidths[placeholderText];
        return (
          <OutlinedInput
            key={index}
            placeholder={`<${placeholders[placeholderIndex]}>`}
            inputProps={{ autoComplete: "off" }}
            style={{ maxWidth: width, minWidth: width }}
            fullWidth={true}
            className={classes.inputParameterInput}
            value={inputValues[placeholders[placeholderIndex]] || ""}
            onChange={(event) =>
              handleInputChange(
                placeholders[placeholderIndex],
                event,
                placeholderIndex
              )
            }
            inputRef={(el) => (inputRefs.current[placeholderIndex] = el)}
          />
        );
      }
      return (
        <Typography key={index} className={classes.inputParameterText}>
          {part}
        </Typography>
      );
    });
  };

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
      setIsOverflow(
        containerRef.current.scrollWidth > containerRef.current.clientWidth
      );
    }
  }, []);

  return (
    <Box
      className={
        model === "locations"
          ? classes.locationParameterContainer
          : classes.parameterContainer
      }
    >
      <Box
        className={clsx(
          { [classes.overflowLunaIcon]: isOverflow },
          { [classes.noOverflowLunaIcon]: !isOverflow }
        )}
      >
        <img src={lunaSolidPlaceholder} width="40" height="40" alt="Luna" />
      </Box>
      <Box
        className={clsx(classes.inputParameterContainer, {
          [classes.overflowPrompt]: isOverflow,
          [classes.noOverflowPrompt]: !isOverflow,
        })}
        ref={containerRef}
      >
        {renderPromptWithPlaceholders(
          selectedAction?.prompt,
          selectedAction?.placeholders
        )}
      </Box>
      <Box style={{ padding: "6px 5px 0px 5px" }}>
        <SearchIcon className={classes.searchIcon} />
      </Box>
    </Box>
  );
};

export default LunaSearchInputParameters;
